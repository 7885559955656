import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import i18n from '../i18n';

import { addFilesToQueue, setCountNeedsReview, setUser } from '../store';

import LazyImage from './LazyImage';

import iconUpload from '../img/icon-upload.png';
import iconGetty from '../img/icon-getty.svg';
import iconSettings from '../img/icon-settings.svg';
import iconBoards from '../img/icon-boards.svg';

import styles from '../styles/sidebar.module.scss';
import PopoutMenu from "./PopoutMenu";

import * as api from '../api';
import { CONTEXT_FIELD } from "../helpers";

//import LangSelectButton from "./LangSelectButton";

const SettingsButton = ({ isMinified, className = null, ...props }) => (
    <button className={classNames(styles.button, className)} {...props}>
        <span style={isMinified ? { opacity: 0 } : null}>{i18n('Settings')}</span>
        <LazyImage src={iconSettings} width={30} height={30} style={{
            maxWidth: 30,
            opacity: isMinified ? 1 : 0
        }}/>
    </button>
);

const Sidebar = ({ context, uploadQueue, countNeedsReview, dispatch, history, allowedExtensions }) => {

    const containerRef = useRef(null);
    const uploadInputRef = useRef(null);
    const [isMinified, setIsMinified] = useState(null);

    const onResize = () => {
        const { current: el } = containerRef;
        if (!el) {
            return;
        }
        const { width } = el.getBoundingClientRect();
        setIsMinified(width <= 200);
    };

    const onUploadInputChange = e => {
        const { files } = e.target;
        if (!files || !files.length) {
            return;
        }
        dispatch(addFilesToQueue({
            files: Array.from(files)
        }));
    };

    // Clear the input field when the upload queue clears
    useEffect(() => {
        const { current: uploadInput } = uploadInputRef;
        if (!uploadInput) {
            return;
        }
        if (!uploadQueue || !uploadQueue.length) {
            uploadInput.value = '';
        }
    }, [uploadQueue]);

    // Resize handler
    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    });

    return (
        <nav ref={containerRef} className={styles.root}>
            {/* Primary */}
            <div className={styles.primary}>
                {/* Upload */}
                <label htmlFor="input-upload" className={styles.button}
                       style={isMinified ? { backgroundColor: '#4C9D2F' } : null}>
                    <span style={isMinified ? { opacity: 0 } : null}>{i18n('Upload')}</span>
                    <LazyImage src={iconUpload} width={33} height={20} style={{
                        maxWidth: 33,
                        opacity: isMinified ? 1 : 0
                    }}/>
                </label>
                <input ref={uploadInputRef} id="input-upload" type="file"
                       accept={allowedExtensions.map(extension => `.${extension}`).join(',')} multiple style={{
                    display: 'none'
                }} onChange={onUploadInputChange}/>
                {/* Getty */}
                <button className={styles.button} disabled={true} style={{ opacity: 0.5, cursor: 'default' }} hidden>
                    <span style={isMinified ? { opacity: 0 } : null}>{i18n('Getty Images')}</span>
                    <LazyImage src={iconGetty} width={18} height={27} style={{
                        maxWidth: 18,
                        opacity: isMinified ? 1 : 0
                    }}/>
                </button>
                {/* Boards */}
                <button className={styles.button} style={{ opacity: 0.5, cursor: 'default' }} disabled={true} hidden>
                    <span style={isMinified ? { opacity: 0 } : null}>{i18n('Boards')}</span>
                    <LazyImage src={iconBoards} width={25} height={21} style={{
                        maxWidth: 25,
                        opacity: isMinified ? 1 : 0
                    }}/>
                </button>
            </div>
            {/* Secondary */}
            <div className={styles.secondary}>
                <div style={{ display: 'flex', position: 'relative' }}>
                    {context !== CONTEXT_FIELD ? (
                        <PopoutMenu
                            position="bottom right"
                            isMinified={isMinified}
                            styles={{
                                menu: {
                                    width: 'auto',
                                    minWidth: 0
                                }
                            }}
                            TriggerComponent={SettingsButton}
                            onOpen={() => {
                                api
                                    .getCountNeedsReview()
                                    .then(res => {
                                        const { status, data } = res;
                                        if (status === 200 && data && !isNaN(data)) {
                                            dispatch(setCountNeedsReview(parseInt(data, 10)));
                                        }
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            }}
                        >
                            <button onClick={() => {
                                history.push('/account');
                            }}>{i18n('My Account')}</button>
                            <button onClick={() => {
                                api.logout()
                                    .then(res => {
                                        const { status, data } = res;
                                        if (status === 200 && data && data.success) {
                                            dispatch(setUser(false));
                                        } else if (data.error) {
                                            console.warn(data.error);
                                        } else {
                                            console.error('Could not log out user');
                                            console.log({ res });
                                        }
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            }}>{i18n('Log out')}</button>
                            {countNeedsReview ? (
                                <>
                                    <hr/>
                                    <button onClick={() => {
                                        api
                                            .getFilesByCriteria({
                                                metaQualityRating: '< 2'
                                            })
                                            .then(res => {
                                                const { status, data: body } = res;
                                                if (status === 200 && body && body.data) {
                                                    const assetIds = body.data.map(asset => asset.id);
                                                    history.push(`/edit/${assetIds.join(',')}`);
                                                }
                                            })
                                            .catch(error => {
                                                console.error(error);
                                            });
                                    }} style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>{i18n('Review Images')} <span style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 24,
                                        height: 24,
                                        flex: '0 0 auto',
                                        backgroundColor: '#C8614D',
                                        color: 'white',
                                        marginLeft: 10,
                                        fontSize: 12,
                                        fontWeight: 500,
                                        borderRadius: '100%',
                                        lineHeight: 1
                                    }}>{countNeedsReview}</span></button>
                                </>
                            ) : null}
                        </PopoutMenu>
                    ) : null}
                </div>
            </div>
        </nav>
    );
};

export default withRouter(connect(state => ({
    context: state.context,
    uploadQueue: state.uploadQueue,
    countNeedsReview: state.countNeedsReview,
    allowedExtensions: state.allowedExtensions
}))(Sidebar));
